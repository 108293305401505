import {useMemo} from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import styled from 'styled-components/macro';
import ErrorMessage from './ErrorMessage';
import Spinner from './Spinner';

const LoadableData = ({
  isLoading,
  isError,
  errorMessage,
  children,
  refetch,
  className
}) => {
  const showChildren = useMemo(() => !isLoading && !isError, [isLoading, isError]);

  return (
    <PullToRefresh onRefresh={refetch}>
      <div className={className}>
        {isLoading && <Spinner />}
        {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {showChildren && children}
      </div>
    </PullToRefresh>
  );
};

export default styled(LoadableData)`
  ${Spinner} {
    margin: 60px auto;
  }
`;
