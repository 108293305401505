import styled from 'styled-components/macro';

const ErrorMessage = styled.div`
  padding: 20px 0;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 20px;
`;

export default ErrorMessage;
