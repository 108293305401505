import styled from 'styled-components/macro';

const BackIcon = ({className, width = 15, height = 24, ...other}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...other}
  >
    <path
      d="M13 2L3 12L13 22"
      stroke="white"
      strokeWidth="2.83333"
      strokeLinecap="round"
    />
  </svg>
);

export default styled(BackIcon)``;
