import styled from 'styled-components/macro';

const BurgerIcon = ({className, width = 48, height = 33}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M0 3H47.7071" stroke="white" strokeWidth="5.30079" />
    <path d="M0 16.252H47.7071" stroke="white" strokeWidth="5.30079" />
    <path d="M0 29.5039H23.8535" stroke="white" strokeWidth="5.30079" />
  </svg>
);

export default styled(BurgerIcon)``;
