import styled from 'styled-components/macro';
import BackgroundLeaf from 'core/components/icons/BackgroundLeaf';
import Header from 'core/components/AppHeader';

const Container = styled.div``;

const Body = styled.div`
  padding: ${({noPadding}) => (noPadding ? 0 : '20px')};
`;

export default function DefaultLayout({
  children,
  noPadding = false,
  withBackground,
  header = {
    label: '',
    back: false,
    backLink: false,
    backHome: false,
    home: false
  }
}) {
  return (
    <Container>
      <Header {...header} />
      <Body noPadding={noPadding}>{children}</Body>
      {withBackground && <BackgroundLeaf />}
    </Container>
  );
}
