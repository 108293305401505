import styled, { keyframes } from 'styled-components/macro';

const Spinner = ({ className }) => {
  return <div className={className} />
};

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const sizes = {
  large: {
    size: '70px',
    borderWidth: '10px'
  },
  small: {
    size: '20px',
    borderWidth: '3px'
  }
};

const StyledSpinner = styled(Spinner)`
  border-radius: 50%;
  width: ${({ size }) => sizes[size]?.size};
  height: ${({ size }) => sizes[size]?.size};
  min-height: ${({ size }) => sizes[size]?.size};

  position: relative;
  text-indent: -9999em;
  border: ${({ size }) => sizes[size]?.borderWidth} solid rgba(255, 255, 255, 0.5);
  border-left: ${({ size }) => sizes[size]?.borderWidth} solid #0a6629;
  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;

  :after {
    border-radius: 50%;

    width: ${({ size }) => sizes[size]?.size};
    height: ${({ size }) => sizes[size]?.size};
    min-height: ${({ size }) => sizes[size]?.size};
  }
`;

StyledSpinner.defaultProps = {
  size: 'large'
};

export default StyledSpinner;
