
import styled from 'styled-components/macro';

const BackgroundLeaf = ({className, width = "100%", height = "100vh"}) => (
    <svg width={width} height={height} viewBox="0 0 425 539" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g opacity="0.6">
    <path d="M358.674 394.115C358.674 394.115 321.382 541.894 203.154 635.186C84.9265 728.477 -69.4476 731.962 -69.4476 731.962C-69.4476 731.962 -32.1553 584.184 86.0725 490.845C204.3 397.6 358.674 394.115 358.674 394.115Z" fill="#34A34F"/>
    <path d="M386.609 482.18C386.609 482.18 359.774 482.792 319.855 490.28C295.503 536.855 258.02 591.907 203.156 635.233C134.253 689.625 53.0312 713.502 -2.7402 723.909C-30.8647 777.642 -41.5605 820.073 -41.5605 820.073C-41.5605 820.073 112.814 816.589 231.041 723.297C349.317 630.005 386.609 482.18 386.609 482.18Z" fill="#5FD16F"/>
    <path d="M319.863 490.28C264.092 500.734 182.917 524.563 113.967 578.956C77.4387 607.777 48.6457 641.826 26.2512 675.073C20.8555 684.304 15.2688 693.063 9.72986 701.351C5.2414 709.028 1.08719 716.563 -2.78052 723.862C52.9909 713.407 134.165 689.578 203.115 635.185C258.027 591.907 295.511 536.808 319.863 490.28Z" fill="#138622"/>
    <path d="M37.038 259.286C37.038 259.286 -77.9428 360.96 -106.211 507.703C-134.478 654.398 -65.337 790.592 -65.337 790.592C-65.337 790.592 -38.0242 766.433 -6.70055 724.661C-44.3749 731.443 -69.4434 732.008 -69.4434 732.008C-69.4434 732.008 -32.5808 585.925 84.0713 492.539C91.2815 366.141 37.038 259.286 37.038 259.286Z" fill="#9DE48D"/>
    <path d="M84.0193 492.492C-32.585 585.878 -69.4954 731.961 -69.4954 731.961C-69.4954 731.961 -44.4269 731.396 -6.7525 724.615C-1.30906 717.363 4.22989 709.592 9.76884 701.351C14.8303 692.733 20.3215 683.926 26.2902 675.073C48.3027 637.587 68.2142 592.565 77.9074 542.176C81.1066 525.41 83.0643 508.786 84.0193 492.492Z" fill="#20922C"/>
    <path d="M858.977 35.6875C858.977 35.6875 762.619 154.692 615.694 190.907C468.768 227.121 327 166.795 327 166.795C327 166.795 423.359 47.7905 570.284 11.5758C717.209 -24.6389 858.977 35.6875 858.977 35.6875Z" fill="#34A34F"/>
    </g>
    </svg>
  );
  
  export default styled(BackgroundLeaf)`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  `;